// Generated by Framer (01933e6)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, SVG, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["aWRlmXwLt", "OOcR1N3OS", "wkImydYWB"];

const serializationHash = "framer-BUZbl"

const variantClassNames = {aWRlmXwLt: "framer-v-rszkpm", OOcR1N3OS: "framer-v-123k6ue", wkImydYWB: "framer-v-rg0i6h"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "aWRlmXwLt", "Variant 2": "OOcR1N3OS", "Variant 3": "wkImydYWB"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "aWRlmXwLt"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "aWRlmXwLt", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppearjk1e55 = activeVariantCallback(async (...args) => {
setVariant("OOcR1N3OS")
})

const onAppear15hfl83 = activeVariantCallback(async (...args) => {
await delay(() => setVariant("OOcR1N3OS"), 2000)
})

useOnVariantChange(baseVariant, {default: onAppearjk1e55, OOcR1N3OS: onAppear15hfl83})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-rszkpm", className, classNames)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"aWRlmXwLt"} ref={ref ?? ref1} style={{rotate: 0, ...style}} variants={{OOcR1N3OS: {rotate: -8}}} {...addPropertyOverrides({OOcR1N3OS: {"data-framer-name": "Variant 2"}, wkImydYWB: {"data-framer-name": "Variant 3"}}, baseVariant, gestureVariant)}><SVG className={"framer-v7ln8u"} data-framer-name={"Icon"} fill={"rgb(255, 255, 255)"} intrinsicHeight={32} intrinsicWidth={32} layoutDependency={layoutDependency} layoutId={"HSpHiLaim"} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"32\" height=\"32\" viewBox=\"0 0 256 256\"><path d=\"M243.31 136 144 36.69A15.86 15.86 0 0 0 132.69 32H40a8 8 0 0 0-8 8v92.69A15.86 15.86 0 0 0 36.69 144L136 243.31a16 16 0 0 0 22.63 0l84.68-84.68a16 16 0 0 0 0-22.63Zm-96 96L48 132.69V48h84.69L232 147.31ZM96 84a12 12 0 1 1-12-12 12 12 0 0 1 12 12Z\"/></svg>"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-BUZbl.framer-1b2e0gf, .framer-BUZbl .framer-1b2e0gf { display: block; }", ".framer-BUZbl.framer-rszkpm { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 0px; position: relative; width: min-content; }", ".framer-BUZbl .framer-v7ln8u { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 32px); position: relative; width: 32px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-BUZbl.framer-rszkpm { gap: 0px; } .framer-BUZbl.framer-rszkpm > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-BUZbl.framer-rszkpm > :first-child { margin-left: 0px; } .framer-BUZbl.framer-rszkpm > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 32
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"OOcR1N3OS":{"layout":["auto","auto"]},"wkImydYWB":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerCTZubTQ3S: React.ComponentType<Props> = withCSS(Component, css, "framer-BUZbl") as typeof Component;
export default FramerCTZubTQ3S;

FramerCTZubTQ3S.displayName = "has deal";

FramerCTZubTQ3S.defaultProps = {height: 32, width: 32};

addPropertyControls(FramerCTZubTQ3S, {variant: {options: ["aWRlmXwLt", "OOcR1N3OS", "wkImydYWB"], optionTitles: ["Variant 1", "Variant 2", "Variant 3"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerCTZubTQ3S, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})